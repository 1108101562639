import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import { StaticImage } from "gatsby-plugin-image";

// Helper Functions
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import MobileAppDownloadBtn from "../../../components/mobile-online-banking/mobile-app-download-btn";

//Styles
import styles from "./zelle.module.scss";

import MobileLoginSVG from "../../../images/third-party/zelle/mobile-login-icon.svg";
import MobileEnrollSVG from "../../../images/third-party/zelle/mobile-enroll-icon.svg";
import MobileMenuSVG from "../../../images/third-party/zelle/mobile-menu-icon.svg";
import FaqAccordion from "../../../components/faq/faq-accordion";

const OnlineBanking = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/business-banking/online-banking/zelle/hero-zelle-business-012224-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/business-banking/online-banking/zelle/hero-zelle-business-012224-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/business-banking/online-banking/zelle/hero-zelle-business-012224-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/business-banking/online-banking/zelle/hero-zelle-business-012224-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/business-banking/online-banking/zelle/hero-zelle-business-012224-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/business-banking/online-banking/zelle/hero-zelle-business-012224-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/business-banking/online-banking/zelle/hero-zelle-business-012224-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "business-credit-solutions-hero",
    ...getHeroImgVariables(imgData),
    altText: "People buying flowers at an outdoor booth with a Zelle accepted here sign.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Send and Receive Money with Zelle<sup>&reg;</sup>"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white btn-long",
            text: "Download the WaFd Treasury App",
            url: "#zelle-intro-section",
            icon: {
              position: "right",
              lib: "fas",
              name: "arrow-down",
              class: "float-right mt-1 ml-3"
            }
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      active: true,
      title: "Zelle<sup>&reg;</sup>"
    }
  ];

  const SEOData = {
    title: "Zelle send and receive money by WaFd Bank",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: "Zelle send and receive money by WaFd Bank"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "With Zelle you can connect your WaFd Bank Treasury account and send money with just an email address or mobile number."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/online-banking/zelle"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-zelle-business-012224.jpg"
      }
    ]
  };

  const faqAccordionData = {
    id: "zelle-faq-accordion",
    showContactBar: false,
    faqCategoryNames: ["Zelle Business"]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section id="zelle-intro-section" className="container">
        <h1>
          Zelle<sup>&reg;</sup>
        </h1>
        <div className="row">
          <div className="col-md-6">
            <h3>
              Zelle<sup>&reg;</sup> is a convenient way for your small business to send and receive money with Zelle
              <sup>&reg;</sup>. Use Zelle<sup>&reg;</sup> to accept payments from your customers or pay an eligible
              vendor, supplier or employee.<sup>1</sup>
            </h3>
            <StaticImage
              src="../../../images/third-party/zelle/zelle_standard_logo_lockup.png"
              alt="WaFd Bank's and Zelle's logos."
              placeholder="blurred"
              quality="100"
              objectFit="contain"
              height="38"
            />
          </div>
          <div className="col-md-6">
            <h3 className="text-success">
              Send and receive money with Zelle<sup>&reg;</sup>
            </h3>
            <h5>Download the WaFd Treasury mobile app:</h5>
            <div className="row mt-4">
              <div className="col-auto mb-3 mb-sm-0 mr-md-4">
                <MobileAppDownloadBtn
                  idName="google-play-store"
                  storeBtn="google"
                  url="https://play.google.com/store/apps/details?id=com.obs.android.olm.washfed.prod&hl=en_US"
                />
                <p className="text-secondary text-sm">For Android devices</p>
              </div>
              <div className="col-auto mb-3 mb-sm-0">
                <MobileAppDownloadBtn
                  idName="apple-store"
                  storeBtn="apple"
                  url="https://apps.apple.com/us/app/wafd-treasury/id1343834928"
                />
                <p className="text-secondary text-sm">For Apple devices</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="why-use-zelle-section" className="bg-gray-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="font-weight-semi-bold ml-3 mb-4">
                Why Use Zelle<sup>&reg;</sup>
              </h2>
            </div>
            <div className="col-md-4 border-bottom border-md-bottom-0 border-md-right text-center">
              <h3 className="font-weight-semi-bold text-success">EASY</h3>
              <h4 className="mb-md-0">
                Your customers can pay you with Zelle<sup>&reg;</sup> right from their banking app, allowing you to
                receive payments while on the go with no extra hardware or trip to the bank.
              </h4>
            </div>
            <div className="col-md-4 border-bottom border-md-bottom-0 border-md-right text-center">
              <h3 className="mt-2 mt-md-0 font-weight-semi-bold text-success">FAST</h3>
              <h4 className="mb-md-0">
                Enhance cash flow. No need to wait for a check to clear, payments are sent directly to your bank
                account, typically within minutes.<sup>1</sup>
              </h4>
            </div>
            <div className="col-md-4 text-center">
              <h3 className="mt-2 mt-md-0 font-weight-semi-bold text-success">SAFE</h3>
              <h4 className="mb-md-0">
                No need to provide your account information to send and receive payments<sup>1</sup> with Zelle
                <sup>&reg;</sup>.
              </h4>
            </div>
          </div>
        </div>
      </section>

      <section id="enroll-with-zelle-now-section" className="bg-green-60 text-white">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="font-weight-semi-bold mb-4">
                Enroll with Zelle<sup>&reg;</sup> Now
              </h2>
            </div>
            <div className="col-md-4 text-center">
              <img src={MobileLoginSVG} alt="Mobile Login Icon" className={styles.mobileIcon} />
              <h4 className="font-weight-semi-bold mb-0">1</h4>
              <h4 className="mb-5 mb-md-3">Log in to the WaFd Treasury mobile app.</h4>
            </div>
            <div className="col-md-4 text-center">
              <img src={MobileMenuSVG} alt="Mobile Menu Icon" className={styles.mobileIcon} />
              <h4 className="font-weight-semi-bold mb-0">2</h4>
              <h4 className="mb-5 mb-md-3">
                In the main menu, select "Zelle<sup>&reg;</sup>" located at the bottom of the screen.
              </h4>
            </div>
            <div className="col-md-4 text-center">
              <img src={MobileEnrollSVG} alt="Mobile Enroll Icon" className={styles.mobileIcon} />
              <h4 className="font-weight-semi-bold mb-0">3</h4>
              <h4 className="mb-5 mb-md-3">Enroll your email address or U.S. mobile number.</h4>
            </div>
            <div className="col-12 text-center mt-5">
              <h4 className="font-weight-semi-bold">
                You're ready to start sending and receiving money with Zelle<sup>&reg;</sup>. Next time you need to be
                paid, ask for Zelle<sup>&reg;</sup>!
              </h4>
            </div>
          </div>
        </div>
      </section>

      <FaqAccordion {...faqAccordionData} />

      <section id="mortgage-calculator-disclaimer-section" className="container py-0">
        <StaticImage
          src="../../../images/third-party/zelle/zelle_standard_logo_lockup.png"
          alt="WaFd Bank's and Zelle's logos."
          placeholder="blurred"
          quality="100"
          objectFit="contain"
          height="38"
        />
        <div className="text-gray-60 text-sm mt-4">
          <p>
            <sup>1</sup> To send or receive money with a small business, both parties must be enrolled with Zelle
            <sup>&reg;</sup> directly through their financial institution's online or mobile banking experience.
            Transactions between enrolled users typically occur in minutes.
          </p>
          <p>
            <sup>2</sup> Payment requests to persons not already enrolled with Zelle<sup>&reg;</sup> must be sent to an
            email address.
          </p>
          <p>
            Zelle<sup>&reg;</sup> and the Zelle<sup>&reg;</sup> related marks are wholly owned by Early Warning
            Services, LLC and are used herein under license.
          </p>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default OnlineBanking;
